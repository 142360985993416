import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { ReactChild, ReactElement, ReactNode, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Modal from 'components/global/Modal'
import OverlayVideo from 'components/overlay-video'

interface Props {
	name: string
	children?: ReactElement[]
}

const Slider = ({ name, children }: Props) => {
	const data = useStaticQuery<SliderQuery>(query).allContentfulSlider.edges.filter((slider) => slider.node.name === name)[0].node

	const doesDataContainVideos = data.slides.map((s) => s.video?.title).filter((s) => s !== undefined).length > 0

	const [videoTitle, setVideoTitle] = useState('')
	const [modalOpen, setModalOpen] = useState(false)

	function handleVideoModalOpen(title: string) {
		setVideoTitle(title)
		setModalOpen(true)
	}

	function handleModalClose() {
		setModalOpen(false)
	}

	if (children) {
		return (
			<Carousel showThumbs={false} showStatus={false} infiniteLoop>
				{/* @ts-ignore */}
				{children}
			</Carousel>
		)
	}
	return (
		<>
			<Carousel autoPlay={data.rotate} showThumbs={false} dynamicHeight showStatus={false} infiniteLoop>
				{data.slides.map((slide) => {
					return (
						<div key={slide.id}>
							{slide.externalLink && (
								<a href={slide.externalLink} target="_blank">
									<GatsbyImage image={slide.image.gatsbyImageData} alt={slide.name} />
								</a>
							)}
							{slide.document && (
								<a href={slide.document.document.file.url} target="_blank">
									<GatsbyImage image={slide.image.gatsbyImageData} alt={slide.name} />
								</a>
							)}
							{slide.internalLink && (
								<Link to={slide.internalLink}>
									<GatsbyImage image={slide.image.gatsbyImageData} alt={slide.name} />
								</Link>
							)}
							{slide.video && (
								<button onClick={() => handleVideoModalOpen(slide.video ? slide.video.title : '')}>
									<GatsbyImage image={slide.image.gatsbyImageData} alt={slide.name} />
								</button>
							)}
							{!slide.externalLink && !slide.internalLink && !slide.video && <GatsbyImage image={slide.image.gatsbyImageData} alt={slide.name} />}
						</div>
					)
				})}
			</Carousel>
			{doesDataContainVideos && (
				<Modal wide isOpen={modalOpen} title={videoTitle} handleClose={handleModalClose}>
					<OverlayVideo title={videoTitle} />
				</Modal>
			)}
		</>
	)
}

interface Slide {
	name: string
	rotate: boolean
	slides: {
		id: string
		name: string
		externalLink?: string
		internalLink?: string
		document?: {
			document: {
				file: {
					url: string
				}
			}
		}
		video?: {
			title: string
		}
		image: {
			gatsbyImageData: IGatsbyImageData
		}
	}[]
}

interface SliderNode {
	node: Slide
}

interface SliderQuery {
	allContentfulSlider: {
		edges: SliderNode[]
	}
}

const query = graphql`
	query {
		allContentfulSlider {
			edges {
				node {
					name
					rotate
					slides {
						id
						name
						externalLink
						internalLink
						document {
							document {
								file {
									url
								}
							}
						}
						video {
							title
						}
						image {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`

export default Slider
